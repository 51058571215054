.board-view .ck.ck-content * {
  font-family: unset !important; /* 기존 폰트 제거 */
  font-size: unset !important; /* 기존 폰트 크기 제거 */
  line-height: unset !important; /* 기존 폰트 높이 제거 */
  font-size: 16px;
  line-height: 1.6;
}

.board-view .ck.ck-content p {
  margin: unset !important; /* 기존 마진 제거 */
  margin: 0.5rem 0 !important;
}

.board-view .ck.ck-content h2 {
  font-size: 1.5rem !important;
}

.board-view .ck.ck-content h3 {
  font-size: 1.125rem !important;
}

.board-view .ck.ck-content h4 {
  font-size: 1rem !important;
}

.board-view .ck.ck-content .prose-h5 {
  font-size: 1.25rem !important;
}

.ck.ck-content h1,
.ck.ck-content h2,
.ck.ck-content h3,
.ck.ck-content h4,
.ck.ck-content h6,
.ck.ck-content b,
.ck.ck-content strong {
  font-weight: 700;
  margin: 0;
  padding: 0;
  overflow-wrap: break-word;
  white-space: pre-line;
  word-break: keep-all;
}

.ck.ck-content h3 {
  margin: 0.5rem 0;
  line-height: 1.625;
}

/* strong 태그 내의 암용어 Popover 폰트 굵기 */
.ck.ck-content strong > span > button[data-popover-button] > span {
  font-weight: 700;
}

.ck.ck-content .info-box {
  --background-size: 30px;
  --background-color: #e91e63;
  padding: 1.2em 2em;
  border: 1px solid var(--background-color);
  background: linear-gradient(
      135deg,
      var(--background-color) 0%,
      var(--background-color) var(--background-size),
      transparent var(--background-size)
    ),
    linear-gradient(
      135deg,
      transparent calc(100% - var(--background-size)),
      var(--background-color) calc(100% - var(--background-size)),
      var(--background-color)
    );
  border-radius: 10px;
  margin: 1.5em 2em;
  box-shadow: 5px 5px 0 #ffe6ef;
}

/* 꼭 알아두세요! */
.ck.ck-content .aside {
  position: relative;
  margin: 1.25rem 0;
  padding: 1rem 1.25rem 1rem 0.75rem;
  background: #eeecff;
  border-radius: 16px;
}
.ck.ck-content .aside-content {
  padding: 1.75rem 0 0 1.375rem;
  margin: 0;
  border: 0;
  line-height: 1.375;
  font-size: 15px;
}
.ck.ck-content .aside-content:focus {
  background: #eeecff;
  box-shadow: none;
  border: 0;
}
.ck.ck-content .aside-content p {
  padding: 0;
  margin: 0;
  line-height: 1.375;
  font-size: 15px;
}
/* 신 에디터의 aside 템플릿 타이틀 */
.ck.ck-content .aside::before {
  content: '';
  position: absolute;
  top: 16px;
  left: 12px;
  padding-left: 1.375rem;
  font-size: 15px;
  font-weight: 700;
  background-position: 0 2px;
  background-repeat: no-repeat;
  background-size: 18px 19px;
}
.ck.ck-content .aside.aside1::before {
  content: '꼭 알아두세요!';
  background-image: url('https://static.lunit.care/icons/index-pointing-up.png');
}
.ck.ck-content .aside.aside2::before {
  content: '여기서 잠깐!';
  background-image: url('https://static.lunit.care/icons/light.png');
}
.ck.ck-content p > img {
  display: inline-block;
  margin: 1rem 0;
}

.ck.ck-content .table {
  display: block;
  width: 100%;
  margin: 1.25rem 0;
  padding-bottom: 0.8rem;
  overflow-x: auto;
}
.ck.ck-content table {
  min-width: 500px;
}
.ck.ck-content table,
.ck.ck-content table th,
.ck.ck-content table td {
  line-height: 1.46666667;
}
.ck.ck-content h1,
.ck.ck-content h2,
.ck.ck-content h3,
.ck.ck-content h4 {
  margin: 0;
  line-height: 1.3;
  font-weight: 700;
}

.ck.ck-content h2 {
  margin: 1.5rem 0;
  font-size: 1.5rem;
}

.ck.ck-content h3 {
  margin-top: 1.5rem;
  font-size: 1.125rem;
}

.ck.ck-content h4 {
  font-size: 1rem;
}

.ck.ck-content p {
  font-size: 16px;
  line-height: 1.6;
  margin: 0.5rem 0;
}

.ck.ck-content ul,
.ck.ck-content ol,
.content-wrapper ul,
.content-wrapper ol {
  margin: 0.5em 0;
  padding: 0 0 0 1.125rem;
}

.ck.ck-content ol li,
.content-wrapper ol li {
  list-style-type: decimal;
}

.ck.ck-content ul li,
.content-wrapper ul li {
  margin-top: 0.25rem;
}

.ck.ck-content ul li,
.content-wrapper ul li {
  list-style-type: disc;
}

.ck.ck-content ul li ul li,
.content-wrapper ul li ul li {
  list-style-type: circle;
}

.ck.ck-content ul li ul li ul li,
.content-wrapper ul li ul li ul li {
  list-style-type: square;
}

.ck.ck-content table {
  border-radius: 5px;
  border-collapse: collapse;
  border-spacing: 0;
  background-color: #b2cdff;
}
.ck.ck-content table,
.ck.ck-content th,
.ck.ck-content td {
  border: 1px solid #b2cdff;
  line-height: 1.5;
}

.ck.ck-content th,
.ck.ck-content td {
  padding: 0.875rem 1.25rem;
}

.ck.ck-content th {
  background-color: #ebf2ff;
  color: #5f676f;
  font-weight: 400;
}

.ck.ck-content td {
  background-color: #fff;
  color: #9199a1;
}

.ck.ck-content .image {
  margin: 1.25rem 0;
}

.ck.ck-content figcaption,
.ck.ck-content .figcaption {
  margin-top: 1rem;
  color: #75777a;
  text-align: center;
}

.ck.ck-content a {
  text-decoration: underline;
  color: #6590ff;
}

.ck.ck-content .video,
.media {
  display: block;
  position: relative;
  width: 100%;
  max-width: 100%;
  height: auto;
  margin: 0.625rem 0;
  padding-top: 50%;
}

.ck.ck-content iframe,
.media iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ck.ck-content blockquote {
  margin: 1.125rem 0;
  padding: 0;
  border: 0;
  font-style: 1rem;
  line-height: 1.6;
  color: #8c8e91;
}

/** 루닛케어 콘텐트 스타일 */
.content-wrapper h1 {
  font-size: 2rem;
  line-height: 1.2;
  margin: 0;
}
.content-wrapper h2 {
  font-size: 1.75rem;
  line-height: 1.5;
  margin: 2rem 0 0.75rem;
}
.content-wrapper h3 {
  font-size: 1.5rem;
  line-height: 1.6;
  margin: 1.5rem 0 0.75rem;
}
.content-wrapper h4 {
  font-size: 1.25rem;
  line-height: 1.6;
  margin: 1.25rem 0 0.5rem;
}
.content-wrapper h5 {
  font-size: 1.125rem;
  line-height: 1.6;
  margin: 1rem 0 0.5rem;
}
.content-wrapper h6 {
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.6;
  margin: 0.75rem 0 0.5rem;
}
.content-wrapper p {
  font-size: 1.125rem;
  line-height: 1.6;
  margin: 0 0 1rem;
}
.content-wrapper li {
  font-size: 1.125rem;
  line-height: 1.6;
  margin: 0;
}
.content-wrapper ul {
  margin-top: 0;
  margin-bottom: 1rem;
  margin: 0 0 1rem;
}
